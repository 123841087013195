import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "@/lib/frontend/shadcn"
import Link, { LinkProps } from "next/link"
import { Spinner } from "./spinner"

export const buttonVariants = cva(
  "font-semibold inline-flex items-center justify-center whitespace-nowrap ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
        dark: "text-white bg-dark",
        darkPrimary: "text-primary bg-dark",
        transparent: "text-black bg-none",
      },
      size: {
        default: "h-9 text-sm px-4 py-2",
        sm: "h-7 text-xs px-3",
        lg: "h-11 text-base px-8",
        icon: "h-10 w-10",
        "small-icon": "h-4 w-4",
      },
      fullWidth: {
        true: "w-full",
      },
      rounded: {
        true: "rounded-md",
        sm: "rounded-sm",
        md: "rounded-md",
        lg: "rounded-lg",
        full: "rounded-full",
      },
      disabled: {
        true: "pointer-events-none cursor-not-allowed bg-disabled text-disabled-foreground",
      },
      loading: {
        true: "cursor-wait opacity-70",
      },
      animatePress: {
        true: "active:animate-button-bounce",
      },
    },
    compoundVariants: [
      {
        loading: true,
        disabled: true,
        variant: "default",
        className: "bg-primary text-primary-foreground",
      },
    ],
    defaultVariants: {
      variant: "default",
      size: "default",
      animatePress: true,
      rounded: "full",
    },
  }
)

// General button props type
export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    loading?: boolean
  }

// Specific button props type including HTML attributes for anchor elements
export type ButtonAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean
    loading?: boolean
  }

export type ButtonLinkProps = LinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
  VariantProps<typeof buttonVariants> & { loading?: boolean }

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ asChild = false, className, variant, size, fullWidth, loading, disabled, animatePress, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, fullWidth, disabled, loading, animatePress }), className)}
        ref={ref}
        aria-busy={loading}
        aria-disabled={loading}
        disabled={loading || disabled}
        {...props}
      >
        {loading ? <Spinner color="white" /> : props.children}
      </Comp>
    )
  }
)
Button.displayName = "Button"

export const ButtonAnchor = React.forwardRef<HTMLAnchorElement, ButtonAnchorProps>(
  ({ asChild = false, className, variant, size, fullWidth, disabled, loading, animatePress, ...props }, ref) => {
    const Comp = asChild ? Slot : "a"
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, fullWidth, disabled, loading, animatePress }), className)}
        ref={ref}
        aria-busy={loading}
        aria-disabled={loading}
        {...props}
      >
        {loading ? <Spinner color="white" /> : props.children}
      </Comp>
    )
  }
)
ButtonAnchor.displayName = "ButtonAnchor"

export const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, variant, size, fullWidth, loading, disabled, animatePress, ...props }, ref) => {
    return (
      <Link
        className={cn(buttonVariants({ variant, size, fullWidth, disabled, loading, animatePress }), className)}
        ref={ref}
        aria-busy={loading}
        aria-disabled={loading}
        {...props}
      >
        {loading ? <Spinner color="white" /> : props.children}
      </Link>
    )
  }
)
ButtonLink.displayName = "ButtonLink"
